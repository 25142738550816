import {Component, Input, OnInit, Output, EventEmitter, HostListener, ChangeDetectorRef} from '@angular/core';
import {BehaviorSubject, finalize, forkJoin, tap} from "rxjs";
import {DocumentPageService} from "../../../services/document-page.service";
import {DMSDocument, OutboxWrapperObject, Settings} from "../../../services/data-interfaces";
import {MatDialog} from "@angular/material/dialog";
import {ModalTaskApprovalComponent} from "../modal-task-approval/modal-task-approval.component";

@Component({
  selector: 'app-documents-list-ungrouped',
  templateUrl: './documents-list-ungrouped.component.html',
  styleUrls: ['./documents-list-ungrouped.component.css']
})
export class DocumentsListUngroupedComponent implements OnInit {

  @Input() outboxes: OutboxWrapperObject[] | null = null;
  @Input() loading: boolean | null = true;
  @Input() clientSelectedAll: boolean | null = true;
  @Input() settings: Settings | null = null;
  @Output() reload = new EventEmitter<boolean>();
  @Output() modeChange = new EventEmitter<'send' | 'list'>();

  selectedOutbox: OutboxWrapperObject | null = null;
  displayCommittingEmployeeAsSender: '1' | '0' = '1';
  openTaskStatusId: string | null = null;
  doneTaskStatusId: string | null = null;
  mode: 'send' | 'list' = 'list';
  showOutboxDetails: boolean = false; // Neues Flag für die Steuerung der Detailansicht

  downloadingDocuments: { [documentId: string]: boolean } = {};
  downloadingOutbox: { [outboxId: string]: boolean } = {};

  public orderDocuments$ = new BehaviorSubject({orderBy: 'outboxCreated', order: 'DESC'});

  constructor(private documentService: DocumentPageService,
              private matDialogService: MatDialog,
              private cdr: ChangeDetectorRef
  ) {


  }

  ngOnInit(): void {

  }


  orderBy(orderBy: string) {
    const actualOrderBy = this.orderDocuments$.value.orderBy;
    const actualOrder = this.orderDocuments$.value.order;
    let newOrder = 'ASC';
    let newOrderBy = orderBy;

    if (orderBy === actualOrderBy) {
      newOrderBy = actualOrderBy;
      if (actualOrder === 'ASC') {
        newOrder = 'DESC';
      } else if (actualOrder === 'DESC') {
        newOrder = 'ASC';
      }
    }

    this.orderDocuments$.next({orderBy: newOrderBy, order: newOrder});
    this.reload.emit(true);
  }

  changeSelectedOutbox(outbox: OutboxWrapperObject) {
    this.showOutboxDetails = true;
    this.mode = 'list';
    this.selectedOutbox = outbox;
    this.downloadDocuments(outbox);
    this.modeChange.emit('list');

  }

  checkTaskStatus(): boolean {
    if (this.settings && !this.openTaskStatusId) {
      this.openTaskStatusId = this.settings.documentStatusIdForClientTasks;
      this.doneTaskStatusId = this.settings.documentStatusIdForClientTaskApproval;
    }
    return true;
  }

  documentHasUndoneTasks(documents: DMSDocument[]): boolean {

    if (this.openTaskStatusId) {
      const document = documents.find((document) => (document.state !== undefined && document.state.id == Number.parseInt(this.openTaskStatusId ?? '0')));
      return document !== undefined && document?.taskDone === '0' && !this.documentHasDoneTasks(documents);
    }
    return false;
  }


  documentHasDoneTasks(documents: DMSDocument[]): boolean {

    if (this.doneTaskStatusId) {
      const document = documents.find((document) => (document.state !== undefined && document.state.id == Number.parseInt(this.doneTaskStatusId ?? '0')));
      return document !== undefined && !this.documentHasUndoneTasks(documents);
    }
    return false;
  }


  downloadDocuments(outbox: OutboxWrapperObject | null) {
    if (outbox) {
      if (!this.allDocumentsOfOutboxAreDownloaded(outbox.documents)) {
        this.downloadingOutbox[outbox.outboxDetails.id] = true;
        const downloadObservables = outbox.documents.map(document =>
          this.documentService.downloadDocuments(document).pipe(
            tap(() => document.downloaded = true),
            finalize(() => this.downloadingDocuments[document.id] = false)
          )
        );
        forkJoin(downloadObservables).subscribe({
          complete: () => this.downloadingOutbox[outbox.outboxDetails.id] = false
        });
      }
    }
  }

  isDownloadingOutbox(outbox: OutboxWrapperObject): boolean {
    return this.downloadingOutbox[outbox.outboxDetails.id] || false;
  }

  allDocumentsOfOutboxAreDownloaded(documents: DMSDocument[]): boolean {
    return documents.every(document => document.downloaded !== undefined && document.downloaded);
  }

  approveTasks(outboxId: string) {
    const dialogRef = this.matDialogService.open(ModalTaskApprovalComponent, {
      data: {
        outboxId: outboxId
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      /*
       * task updated successfully
       */
      if (result) {
        const selectedOutbox = this.outboxes?.find(outbox => outbox.outboxDetails.id === outboxId);
        if (selectedOutbox) {
          selectedOutbox.documents.forEach((document) => {
            document.taskDone = '1';
          });
          this.cdr.detectChanges();  // Manuell die View aktualisieren
        }
      }
    });
  }

  setModeToSend() {
    this.mode = 'send';
    this.modeChange.emit('send');
    this.showOutboxDetails = false;
  }


  closeUploadDocuments() {
    this.mode = 'list';
    this.modeChange.emit('list');
    this.showOutboxDetails = false;
  }

  goBack() {
    this.closeUploadDocuments();
  }

  handleModeChange(newMode: 'send' | 'list') {
    this.mode = newMode;
  }


  allDocumentsAreOnlyDocuments(documents: DMSDocument[]): boolean {
    let allDocumentsAreTypeDocuments = true;

    if (documents) {
      for (let document of documents) {
        if (this.documentIsPhoneNote(document)) {
          allDocumentsAreTypeDocuments = false;
          break;
        }
      }
    }

    return allDocumentsAreTypeDocuments;
  }

  documentIsPhoneNote(document: DMSDocument) {
    if (document.class.name === 'Telefonnotiz') {
      return true;
    }
    return false;
  }
}
