import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DMSDocument, OutboxWrapperObject} from "../../../services/data-interfaces";
import {DocumentPageService} from "../../../services/document-page.service";

@Component({
  selector: 'app-outbox-details',
  templateUrl: './outbox-details.component.html',
  styleUrls: ['./outbox-details.component.css']
})
export class OutboxDetailsComponent implements OnInit {

  @Input('outboxDetails') outbox: OutboxWrapperObject | null = null;
  @Input('displayCommittingEmployeeAsSender') displayCommittingEmployeeAsSender: '1' | '0' = '1';
  downloadingDocuments: { [documentId: string]: boolean } = {};
  @Output() modeChange = new EventEmitter<'send' | 'list'>();

  constructor(private documentService: DocumentPageService,
  ) {
  }

  ngOnInit(): void {
  }

  getDocumentTitle(description: string, extension: string): string {
    if (description.includes('.' + extension.toLowerCase().replace(';', '.'))) {
      return description;
    }
    return description + '.' + extension.toLowerCase().replace(';', '.');
  }

  documentIsPhoneNote(document: DMSDocument) {
    if (document.class.name === 'Telefonnotiz') {
      return true;
    }
    return false;
  }

  downloadDocument(document: DMSDocument) {
    this.downloadingDocuments[document.id] = true;
    this.documentService.downloadDocuments(document).subscribe({
      next: () => {
        document.downloaded = true;
        this.downloadingDocuments[document.id] = false;
      },
      error: () => {
        this.downloadingDocuments[document.id] = false;
      }
    });
  }

  isDownloading(document: DMSDocument): boolean {
    return this.downloadingDocuments[document.id] || false;
  }

  hasDocumentNote(document: DMSDocument): boolean {
    return document.note
      && document.note.text !== undefined
      && document.note.text !== null
      && document.note.text.trim().length > 0;
  }

  answerOutbox(sign = false) {
    let clientNumber = this.outbox?.outboxDetails.receiver_number;
    let employeeNumber;
    if (this.displayCommittingEmployeeAsSender === '1') {
      employeeNumber = this.outbox?.outboxDetails.committed_by_employee_number;
    } else {
      employeeNumber = this.outbox?.outboxDetails.creating_employee_number;
    }

    if (clientNumber) {
      this.documentService.setClientNumber(clientNumber.toString());
    }

    if (employeeNumber) {
      this.documentService.setEmployeeNumber(employeeNumber);
    }

    if (sign) {
      let datevDocuments = this.getDatevDocuments();
      this.documentService.setDocuments(datevDocuments);
    }

    this.modeChange.emit('send');

  }

  private getDatevDocuments(): DMSDocument[] {
    const datevDocuments: DMSDocument[] = [];
    if (this.outbox?.documents) {
      for (let document of this.outbox?.documents) {
        if (!this.documentIsPhoneNote(document)) {
          datevDocuments.push(document);
        }
      }
    }

    return datevDocuments;
  }

  allDocumentsAreOnlyPhoneNotes(): boolean {
    let allDocumentsAreOnlyPhoneNotes = true;

    if (this.outbox?.documents) {
      for (let document of this.outbox?.documents) {
        if (!this.documentIsPhoneNote(document)) {
          allDocumentsAreOnlyPhoneNotes = false;
        }
      }
    }

    return allDocumentsAreOnlyPhoneNotes;
  }

  allDocumentsAreOnlyDocuments(): boolean {
    let allDocumentsAreTypeDocuments = true;

    if (this.outbox?.documents) {
      for (let document of this.outbox?.documents) {
        if (this.documentIsPhoneNote(document)) {
          allDocumentsAreTypeDocuments = false;
          break;
        }
      }
    }

    return allDocumentsAreTypeDocuments;
  }
}
